.test-page{
  .type-selector{
    transition: $transitionClassic;
    width: 100%;
    height: 100%;
    aspect-ratio: 1 / 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    cursor: pointer;
    user-select: none;
    background: rgba($black, .02);
    position: relative;

    .check-icon{
      position: absolute;
      top: 20px;
      left: 20px;
    }

    &:hover, &.selected{
      background: rgba($black, .05);
    }

    .anticon{
      font-size: 32px;
      color: $purple;
    }
  }

  .ant-form-item{
    margin-bottom: 15px;

    .ant-form-item-label{
      padding-bottom: 6px;
      line-height: 130%;
      label{
        font-size: 14px;
      }
    }

    .ant-form-item-explain{
      @apply text-xs text-neutral-400 mt-1;
      min-height: unset;
    }
  }
}