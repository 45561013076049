.site-layout{
  height: 100%;
  min-height: 100vh;

  .site-layout-background{
    background-color: $white;
  }

}

.logo-top{
  width: 100%;
  height: 64px;
  padding: 16px 20px;
  box-sizing: border-box;

  img{
    display: block;
    height: 100%;
    margin: 0 auto;
  }
}

.offline-msgbox{
  max-width: 400px;
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 50px;
  
  .msg{
    box-shadow: 0px 5px 10px -5px rgba(36, 8, 13, .2);
  }
}

.ck-content{
  p{
    @apply text-sm text-black;
  }
}