.login-layout{
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
  // display: flex;
  // justify-content: stretch;

  .ant-form-item{
    margin-bottom: 15px;
  }

  // @media only screen and (max-width: $sm) {
  //   .login-bgr{
  //     display: none;
  //   }

  //   .login-content{
  //     width: 100% !important;
  //   }

  //   .login-form{
  //     max-width: unset !important;
  //     width: 100%;
  //   }
  // }

  .login-bgr{
    width: 100%;
    height: 100%;
    // background: url('/img/login-bgr.png') center center no-repeat;
    background-size: cover;
    position: relative;

    .alert-login{
      position: absolute;
      left: 40px;
      right: 40px;
      bottom: 40px;
    }
  }

  .login-content{
    width: 100%;
    height: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h1,h2,h3,h4,h5,h6{
      color: $black;
    }

    h1{
      font-size: 2.000em;
      margin: 1.000em 0;
    }

    .logo-signin{
      img{
        height: 40px;
        display: block;
      }
    }

    .login-form{
      max-width: 400px;

      @media only screen and (max-width: $md) {
        max-width: unset;
        width: 100%;
      }

      .login-checkbox{
        display: block;
        width: 100%;
        margin: 5px 0;

        .ant-form-item-control-input{
          min-height: unset;
        }
      }
    }
  }

}

.social-login-btn{
  border: 1px solid rgba($black, .1);
  outline: 0;
  transition: $transitionClassic;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  line-height: 100%;
  width: 100%;
  cursor: pointer;
  margin: 15px auto;

  svg{
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 15px;
  }

  &:hover{
    opacity: .8;
  }

  &.google{
    background-color: $white;
    color: rgba($black, .54);
  }

  &.apple{
    border-color: $black;
    background-color: $black;
    color: $white;
  }

  &:disabled{
    opacity: .5;
    cursor: not-allowed;
  }
}