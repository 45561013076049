// @tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

// Colors
$white: #FFFFFF;
$black: #000000;
$purple: #4706D1;
$red: #FA1366;
$lightgrey: #F3F4F5;
$altParagraph: #5a6479;

// Breakpoints
$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;

$maxContainerWidth: 1180px;

// Globals
$transitionClassic: all .17s ease-in-out;

// Img
// $loginBGR: url("/img/login-bgr.png");

html, body{
  scroll-behavior: smooth;
  overflow-x: hidden;
  box-sizing: border-box;
  -webkit-font-smoothing: antialised;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  ::selection{
    color: $white;
    background: $black;
  }
}

.bgr-grey{
  background-color: $lightgrey;
}

.container{
  padding: 20px;
  &.max-content{
    max-width: $maxContainerWidth;
    margin: 0 auto;
  }
}

.loading-global{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: $lightgrey;
  color: darken($lightgrey, 20);

  &.compact {
    width: 100%;
    height: 100%;
    min-height: 300px;
    background: none;
  }
}

.ant-layout-footer{
  background: none;
}

@import './components/typography';
@import './components/layout';
@import './components/login';
@import './components/cards';
@import './components/test';