h1{
  font-size: 40px;
  line-height: 120%;
  font-weight: 600;
  color: $black;
}

h2{
  @extend h1;
  font-size: 33px;
}

h3{
  @extend h1;
  font-size: 33px;
}

h4{
  @extend h1;
  font-size: 27px;
}

h5{
  @extend h1;
  font-size: 24px;
}

h6{
  @extend h1;
  font-size: 21px;
}

p{
  font-size: 16px;
  font-weight: 400;
  line-height: 180%;
  color: $altParagraph;
}

.accent{
  font-family: 'Space Grotesk', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 15px;
  line-height: 100%;
  font-weight: 500;
  color: $purple;
  font-style: normal;
}

.accent-title{
  &.m-large{
    margin: 160px 0;
  }

  &.mb-0{
    margin-bottom: 0;
  }

  &.mt-0{
    margin-top: 0;
  }

  h2{
    margin: 20px 0;
  }

  &:after{
    display: block;
    content: "";
    width: 50px;
    height: 4px;
    background: $black;
    margin: 0;
  }

  &.centered{
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;

    h1,h2,h3,h4,h5,h6,i{
      text-align: center;
    }

    &:after{
      margin: 0 auto;
    }
  }
}

.text-error{
  color: $red;
}